import imgT1 from "../assets/image/yeetleap.png";
import imgT2 from "../assets/image/spec.png";
import imgT3 from "../assets/image/spoticleanse.png";

export const devWorks2 = [
  {
    brand: `Spoticleanse`,
    categories: [`Web`],
    thumbnail: imgT3,
    videolink: "https://www.youtube.com/watch?v=siix8Zkdq4w",
    link: "https://www.spoticleanse.com/"
  },
  {
    brand: `Spectrum Run`,
    categories: [`App`],
    thumbnail: imgT2,
    link: "https://play.google.com/store/apps/details?id=com.contingency.spectrumrun"
  },
  {
    brand: `Yeet Leap`,
    categories: [`App`],
    thumbnail: imgT1,
    link: "https://play.google.com/store/apps/details?id=com.onthecan.yeetleap"
  }
];
