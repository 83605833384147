import React, {useContext} from "react";
import styled from "styled-components";
import { Box } from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";

const BoxStyled = styled(Box)`
  transition: 0.4s;
  &:hover {
    transform: scale(0.8) rotate(-16deg);
    border-radius: 20px;
    box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
    overflow: hidden;
    cursor: pointer;
  }
`;

const RotateImg = ({ link = "/", imgSrc, ...rest }) => {
  const gContext = useContext(GlobalContext);



  return (
    <>
      <BoxStyled {...rest} >
      {link.includes("watch") === false && <a href={link} target="_blank" rel="noreferrer" className="d-block">
          <img src={imgSrc} alt="" className="w-100" />
        </a>}

        {link.includes("watch") && <div onClick={(e) => {
                    e.preventDefault();
                    gContext.setVideoModalLink(link);
                    gContext.toggleVideoModal();
                  }}><img src={imgSrc} alt="" className="w-100" /> </div>}
      </BoxStyled>
    </>
  );
};
export default RotateImg;
